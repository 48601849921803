import "./App.css";
export const menuItemsData = [
  {
    title: "About Us",
    url: "/aboutus",
    // submenu: [
    //   {
    //     title: "Media",
    //     url: "media",
    //   },
    // ],
  },
  {
    title: "Our Services",
    url: "",

    submenu: [
      {
        title: "Hair Care",
        url: "/haircare",
      },
      {
        title: "Body Care",
        url: "/bodycare",
      },
      {
        title: "Skin Care",
        url: "/skincare",
      },
    ],
  },
  {
    title: "Academy",
    url: "/academy",
    // submenu: [
    //   { title: "Home", url: "/home" },
    //   { title: "About", url: "/about" },
    //   { title: "Franchise", url: "/franchises" },
    //   { title: "Ourcourse", url: "/ourcourse" },
    //   { title: "Academy Contact", url: "/contact" },
      
    // ],
  },
  {
    title: "Bridal",
    url: "/unikaaBridal",
  },
  {
    title: "Offers",
    url: "/Offers",
  },

  {
    title: "Franchise",
    url: "/franchise",
  },

  // {
  //   title: "Salon Locator",
  //   url: "/salonlocator",
  // },
  // {
  //   title: "Pricing",
  //   url: "",
  //   submenu: [
  //     {
  //       title: "Service For Her",
  //       url: "serviceforher",
  //     },
  //     {
  //       title: "Service For Him",
  //       url: "serviceforhim",
  //     },
  //   ],
  // },

  {
    title: "Blog",
    url: "/blog",
  },
];
