import React from "react";
// import "../components/body.css";

import ScrollTop from "../scrolltop";

const Body = () => {
  return (
    <div className="body-div">
      <div> </div>
      <ScrollTop />
    </div>
  );
};

export default Body;
